export default {
	pageTitle: "BlueMap - {map}",
	menu: {
		title: "Menu",
		tooltip: "Menu"
	},
	maps: {
		title: "Maps",
		button: "Maps",
		tooltip: "Map-List"
	},
	markers: {
		title: "Markers",
		button: "Markers",
		tooltip: "Marker-List",
		marker: "marker | markers",
		markerSet: "marker-set | marker-sets",
		searchPlaceholder: "Search...",
		followPlayerTitle: "Follow Player"
	},
	settings: {
		title: "Settings",
		button: "Settings"
	},
	goFullscreen: {
		button: "Go Fullscreen"
	},
	resetCamera: {
		button: "Reset Camera",
		tooltip: "Reset Camera & Position"
	},
	updateMap: {
		button: "Update Map",
		tooltip: "Clear Tile Cache"
	},
	lighting: {
		title: "Lighting",
		dayNightSwitch: {
			tooltip: "Day/Night"
		},
		sunlight: "Sunlight",
		ambientLight: "Ambient-Light"
	},
	resolution: {
		title: "Resolution",
		high: "High (SSAA, x2)",
		normal: "Normal (Native, x1)",
		low: "Low (Upscaling, x0.5)"
	},
	freeFlightControls: {
		title: "Free-Flight Controls",
		mouseSensitivity: "Mouse-Sensitivity",
		invertMouseY: "Invert Mouse Y"
	},
	renderDistance: {
		title: "Render Distance",
		hiresLayer: "Hires layer",
		lowersLayer: "Lowres layer"
	},
	theme: {
		title: "Theme",
		default: "Default (System/Browser)",
		dark: "Dark",
		light: "Light"
	},
	debug: {
		button: "Debug"
	},
	resetAllSettings: {
		button: "Reset All Settings"
	},
	players: {
		title: "Players",
		tooltip: "Player-List"
	},
	compass: {
		tooltip: "Compass / Face North"
	},
	controls: {
		title: "View / Controls",
		perspective: {
			button: "Perspective",
			tooltip: "Perspective-View"
		},
		flatView: {
			button: "Flat",
			tooltip: "Orthographic / Flat-View",
		},
		freeFlight: {
			button: "Free-Flight",
			tooltip: "Free-Flight / Spectator Mode"
		}
	},
	language: {
		title: "Language",
	},
	blockTooltip: {
		block: "Block",
		position: "Position",
		chunk: "Chunk",
		region: {
			region: "Region",
			file: "File"
		},
		light: {
			light: "Light",
			sun: "Sun",
			block: "Block",
		}
	},
	info: {
		title: "Info",
		button: "Info",
		content: `
<img src="assets/logo.png" style="display: block; width: 40%; margin: 3em auto; border-radius: 50%">
<p>
	<h2>Mouse-Controls:</h2>
	<table>
		<tr><th>move</th><td><kbd>left-click</kbd> + drag</td></tr>
		<tr><th>zoom</th><td><kbd>mousewheel</kbd> (scroll)</td></tr>
		<tr><th>rotate / tilt</th><td><kbd>right-click</kbd> + drag</td></tr>
	</table>
</p>
<p>
	<h2>Keyboard-Controls:</h2>
	<table>
		<tr><th>move</th><td><kbd>wasd</kbd> / <kbd>arrow-keys</kbd></td></tr>
		<tr><th>zoom</th><td>Numpad: <kbd>+</kbd>/<kbd>-</kbd> or <kbd>Ins</kbd>/<kbd>Home</kbd></td></tr>
		<tr><th>rotate / tilt</th><td><kbd>Left-Alt</kbd> + <kbd>wasd</kbd> / <kbd>arrow-keys</kbd> or <kbd>Delete</kbd>/<kbd>End</kbd>/<kbd>Page Up</kbd>/<kbd>Page Down</kbd></td></tr>
	</table>
</p>
<p>
	<h2>Touch-Controls:</h2>
	<table>
		<tr><th>move</th><td>touch + drag</td></tr>
		<tr><th>zoom</th><td>touch with two fingers + pinch</td></tr>
		<tr><th>rotate / tilt</th><td>touch with two fingers + rotate / move up/down</td></tr>
	</table>
</p>
<br><hr>
<p>
	This map has been generated with &#9829; using <a href="https://bluecolo.red/bluemap">BlueMap</a>.
</p>
		`
	}
};
